import { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";

// import { theme } from "./theme/CustomTheme";
import Shared from "./common/Shared";
import { URL } from "./components/Navbar/routes";

import "./MainStyle.scss";
import "./App.css";
import Story from "./components/Walkthru/Walkthru";
import { Success } from "./components/stripe/success";
import { Failed } from "./components/stripe/failed";
import VideoEndContext from "./components/story/context/Videos";

function App() {
  useEffect(() => {
    document.title = Shared.title;
  }, []);

  const [videoEnd, setVideoEnd] = useState(false);
  const [isImage, setImage] = useState(false);

  return (
    // <ThemeProvider theme={theme}>
    <VideoEndContext.Provider
      value={{ videoEnd, setVideoEnd, isImage, setImage }}
    >
      <div className="sans-serif">
        <Switch>
          <Route path={URL.INSTA_STORY} component={Story} />
          <Route path={URL.SUCCESS} component={Success} />
          <Route path={URL.FAILED} component={Failed} />
        </Switch>
      </div>
    </VideoEndContext.Provider>
    // </ThemeProvider>
  );
}

export default App;

import { useEffect, useState } from "react";
// import Stories from "react-insta-stories";
import ReactInstaStories from "../story";
import { useParams } from "react-router-dom";
import apiPlugin from "../../api/apiPlugin";
import { SpinnerLoader } from "../../common/Global.Style";
import "./Walkthru.scoped.scss";
import { setupWebViewJavascriptBridge } from "../jsBridge/index";
import LocationIcon from "../../images/location-icon.png";
import Logo from "../../images/logo.png"


 
// const slideUpAndDown = (slideUp: boolean) => {
//   let slide: any = document.getElementById("slide");
//   let productContent: any = document.getElementById("product-content");
//   let shop: any = document.getElementById("shop");
//   let closebtn: any = document.getElementById("close");
//   closebtn.style.display = "none";
//   shop.addEventListener("click", slideUp);

//   if (slideUp) {
//     slide.style.marginTop = "-630px";
//     closebtn.style.display = "block";
//     productContent.style.padding = "20px";
//   } else {
//     closebtn.style.display = "none";
//     slide.style.marginTop = "0%";
//   }
// }

const callHandler = (funName: any, dataJson: any, callback: any) => {
  setupWebViewJavascriptBridge(function (bridge: any) {
    bridge.callHandler(funName, dataJson, function (response: any) {
      callback && callback(response);
    });
  });
};

const pairDevice = () => {
  callHandler("pairDevice", "", () => {});
};

const seeMore = (cta: any, product: any) => {
  const { textCode, textPhone, callPhone, callCode, calendly } = cta;
  let isButtonEnable =
    textCode && textPhone && callPhone && callCode ? true : false;
  const calendlyPattern =
    /^((https?:\/\/)|(www\.)|(webcal:\/\/))[^\s/$.?#].[^\s]*$/i;

  return ({ close }: any) => (
    <div className="block">
      <div className="container product-bottom">
        <div id="product-content" className="product-container">
          <div className="product-page">
            <div className="product-block">
              <div>
                <div className="product-header">
                  <div className="arrow-down-block" onClick={close}>
                    <div className="arrow-down-container">
                      <div className="arrow-down"></div>
                    </div>
                  </div>
                  <div className="product-title">
                    <p>{product?.name}</p>
                  </div>
                </div>
                <div className="product-list">
                  {/* {product?.images?.map((img: string) => {
                    return ( */}
                      <div className="product">
                        <img
                          src={product?.images[0]?.url}
                          height="120px"
                          width="100px"
                          alt="devices"
                        />
                      </div>
                    {/* );
                  })} */}
                </div>
                {product?.description &&
                  <div>
                  <p className="title align-left">Description</p>
                  <p className="product-desc align-left">
                    {product?.description}
                  </p>
                </div>}
                {product?.specification &&
                <div>
                  <p className="title align-left">Specification</p>
                  <p className="product-desc align-left">
                    {product?.specification}
                  </p>
                </div>
                }
              </div>
              <div className="action-btn">
                {isButtonEnable && (
                  <div className="button-grp">
                    <a
                      href={`tel:${callCode} ${callPhone}`}
                      className="call-btn"
                    >
                      <span className="btn-icon">
                        <img
                          src="https://icloud-image-uploader.s3.amazonaws.com/report_images/Call.png"
                          height="14px"
                          width="14px"
                          alt="call"
                        />
                      </span>
                      Call
                    </a>
                    <a
                      href={`sms:${textCode} ${textPhone}`}
                      className="sms-btn"
                    >
                      <span className="btn-icon">
                        <img
                          src="https://icloud-image-uploader.s3.amazonaws.com/report_images/Chat.png"
                          height="16px"
                          width="16px"
                          alt="chat"
                        />
                      </span>
                      SMS
                    </a>
                  </div>
                )}
                <div>
                  {calendlyPattern?.test(calendly) && (
                    <a
                      href={calendly}
                      target="_blank"
                      rel="noreferrer"
                      className="calender-btn"
                      onClick={pairDevice}
                    >
                      <span className="btn-icon">
                        <img
                          src="https://icloud-image-uploader.s3.amazonaws.com/report_images/Calendar.png"
                          height="16px"
                          width="16px"
                          alt="calendar"
                        />
                      </span>
                      Calendly
                    </a>
                  )}
                </div>

                {product?.btnLink && (
                  <div>
                    <a
                      href={product?.btnLink}
                      rel="noreferrer"
                      target="_blank"
                      className="buy-btn"
                      style={{textTransform:"capitalize"}}
                    >
                      <span className="btn-icon">
                        <img
                          src="https://icloud-image-uploader.s3.amazonaws.com/report_images/Buy.png"
                          height="16px"
                          width="16px"
                          alt="buy"
                        />
                      </span>
                      {product?.btnLabel}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const introPage = (name: string, address: string, logo: string, tenant: string) => {
  return (
    <div className="intro-page">
      <div className="intro-banner"> 
      <div className="into-logo">
        <img src={tenant === 'dust free' ? "https://assets.bldghealth.net/company/logo/dustTitle.png" :Logo} alt="logo"/>
      </div>
      {logo && <div className="video-logo-into">
        <img src={logo} alt="" />
      </div>}
      <div className="intro-desc" >
    Here's a personalized Video Report of your <span style={{fontWeight: 800}}>Air Quality Assessment</span>
   </div>
      </div>
      <div className="intro-add">
       
      <div className="align-item-center" style={{padding:10, width: "100%"}}>
       <div className="guide-next-btn">
         <div style={{fontSize:18,fontWeight:800,color:"#fff",textAlign:"center"}}>
         Click to View Report
      </div>  
     </div>
      <div style={{display:"flex"}}>
          <div className="intro-main">
            <img
              className="icon"
              src={LocationIcon}
              alt="intro"
            />
          </div>
          <div className="intro-content">
            <p className="name">{name}</p>
            <p className="address">
              {address?.length > 90 ? address?.slice(0, 90) + "..." : address}
            </p>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

const exposurePage = (text: string) => {
  return (
    <div className="exposure-page">
      <div>
        <p className="exposure-text">{text}</p>
      </div>
      <img
        className="exposure-img"
        src="https://iqintel-qa-testing.s3.amazonaws.com/assessment-walkthrough/intro/stem-cell-research-rafiki%403x.png"
        alt="img"
      />
    </div>
  );
};

// const errorPage = (errMessage: any) => {
//   return (
//     <>
//       <div
//         style={{
//           maxWidth: "100%",
//           height: "100%",
//           padding: 40,
//           background: "white",
//         }}
//       >
//         <h4>{errMessage}</h4>
//       </div>
//     </>
//   )
// }

const seeMoreCollapsedComponent = ({ toggleMore, action, state }: any) => {
  return (
    <div
      className="shop-text"
      id="slide"
      onClick={() => {
        action("pause", true);
        toggleMore(true);
        state(true);
      }}
      onTouchStart={(e) => {
        e.preventDefault();
        action("pause", true);
        toggleMore(true);
        state(true);
      }}
    >
      <div className="slide-bar-par">
        <div
          className="arrow-up-container"
          onClick={() => {
            action("pause", true);
            toggleMore(true);
            state(true);
          }}
          onTouchStart={(e) => {
            e.preventDefault();
            action("pause", true);
            toggleMore(true);
            state(true);
          }}
        >
          <div className="arrow-up"></div>
        </div>
      </div>
      <h6 id="shop" className="shop-btn">
        Shop now
      </h6>
      {/* <div className="slide-bar-par">
        <div className="slide-bar"></div>
      </div> */}
    </div>
  );
};

const Story = () => {
  let { id }: any = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [slides, setSlides] = useState<any[]>([
    {
      url: "https://icloud-image-uploader.s3.amazonaws.com/report_images/6342464.jpg",
    },
  ]);

  const screenWidth = window.innerWidth >= 1201 ? 810 : "100vw";

  useEffect(() => {
    getWalkthruSlides();
  }, []);

  const getWalkthruSlides = async () => {
    let slide: any[] = [
      {
        url: "https://icloud-image-uploader.s3.amazonaws.com/report_images/6342464.jpg",
      },
    ];
    const response = await getWalkthruImages();
    console.log(response, "response response");

    if (response?.responseObj && response?.responseObj?.data) {
      slide = [];
      const reportData = response?.responseObj?.data;
      slide.push({
        content: () => {
          return introPage(reportData.buildingName, reportData.buildingAddress, reportData?.reportSettings?.logo, reportData?.tenant);
        },
      });
      reportData.exposures?.forEach((e: any, mainIndex: number) => {
        if (e.dimension !== "ch2o_value") {
        // e.text &&
        //   slide.push({
        //     content: () => {
        //       return exposurePage(e.text);
        //     },
        //   });
        e.effects?.forEach((eff: any, index: number) => {
            // const action = eff?.mediaType === "video" ? videoLoaded : imageLoaded;
            // action(eff?.url);
          slide.push({
            id: mainIndex + index,
            url: eff?.url,
            className: "image-layout",
            type: eff?.mediaType,
            text:eff.text,
            logo: reportData?.reportSettings?.logo
          });
        });
        e.products?.forEach((p: any, index: number) => {
          // const action = p?.mediaType === "video" ? imageLoaded : imageLoaded;
          // action(p.images[0]);
          
          slide.push({
            id:  p._id,
            url: p.url ? p?.url?.url : p.video || p.images[0],
            productId: p._id,
            className: "product-img",
            seeMore: seeMore(reportData?.reportSettings || {}, p),
            duration: 8000,
            seeMoreCollapsed: seeMoreCollapsedComponent,
            type: p.mediaType ? p.mediaType : p.video ? 'video' : 'image',
            logo: reportData?.reportSettings?.logo
          });
        });
      }
      });
      setSlides(slide);
    }
    setIsLoading(false);
  };

  const getWalkthruImages = async () => {
    return apiPlugin<any>("GET", `reports/${id}`);
  };

  if (isLoading) {
    return ( 
    <div>
      <SpinnerLoader />
    </div>
    )
  }

  return (
    <div className="insta-story">
        <ReactInstaStories
          stories={slides}
          keyboardNavigation
          defaultInterval={8000}
          loader={<SpinnerLoader />}
          width={screenWidth}
          height={"100vh"}
        />
    </div>
  );
};

export default Story;

import React from "react";
import { VideoEnd } from "../../../interfaces";

export const initialContext = {
  videoEnd: false,
};

const VideoEndContext = React.createContext<VideoEnd>(initialContext);

export default VideoEndContext;

import React, { ComponentType, useState, useContext } from "react";
import {
  Action,
  SeeMoreProps,
  Story,
  StoriesContext as StoriesContextInterface,
} from "../../../../interfaces";
import SeeMore from "../../components/SeeMore";
import StoriesContext from "../../context/Stories";

const WithSeeMore: React.FC<
  React.PropsWithChildren<{
    story: Story;
    action: Action;
    customCollapsed?: SeeMoreProps["customCollapsed"];
  }>
> = ({ story, action, customCollapsed, children }) => {
  const [showMore, setShowMore] = useState(false);
  const data = useContext<StoriesContextInterface>(StoriesContext);
  const { setToggle } = data;
  const toggleMore = (show: boolean) => {
    action(show ? "pause" : "play", true);
    setShowMore(show);
  };
  const state = (show: boolean) => {
    setToggle(show);
  };

  const CollapsedComponent = SeeMore;
  return (
    <>
      <div className="image-div">{children}</div>
      {story.seeMore && (
        <div
          style={{
            position: "absolute",
            margin: "auto",
            bottom: showMore ? "unset" : 0,
            zIndex: 9999,
            width: "100%",
            height: showMore ? "100%" : "auto",
            top: showMore ? "0" : "unset",
          }}
        >
          <CollapsedComponent
            action={action}
            toggleMore={toggleMore}
            showContent={showMore}
            state={state}
            seeMoreContent={story.seeMore}
            customCollapsed={customCollapsed || story.seeMoreCollapsed}
          />
        </div>
      )}
    </>
  );
};

export default WithSeeMore;

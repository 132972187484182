import * as React from "react";
import Spinner from "../components/Spinner";
import { Renderer, Tester, VideoEnd } from "../../../interfaces";
import WithHeader from "./wrappers/withHeader";
import WithSeeMore from "./wrappers/withSeeMore";
import VideoEndContext from "../context/Videos";

export const renderer: Renderer = ({
  story,
  action,
  isPaused,
  config,
  messageHandler,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [loaded, setLoaded] = React.useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [muted, setMuted] = React.useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [styleClass, setStyleClass] = React.useState("");
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const timer = React.useRef<any>();
  const { width, height, loader, storyStyles } = config;
// eslint-disable-next-line react-hooks/rules-of-hooks
  const { setVideoEnd } = React.useContext<VideoEnd>(VideoEndContext)

  let computedStyles = {
    // ...styles.storyContent,
    ...(storyStyles || {}),
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  let vid = React.useRef<HTMLVideoElement>(null);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    if (vid.current) {
      if (isPaused) {
        action("pause", true);
        vid.current.pause();
      } else {
        action("play", true);
        vid.current.play().catch(() => {});
      }
    }
  }, [isPaused]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    if (timer.current) {
      setTimeout(() => {
        setStyleClass("content-header");
      }, 200);
    }
    return () => {
      setStyleClass("");
    };
  }, [timer.current]);

  const onWaiting = () => {
    action("pause", true);
  };

  const onPlaying = () => {
    action("play", true);
  };

  const videoLoaded = () => {
    if (vid.current) {
      timer.current = vid.current.duration + 0.3;
      messageHandler("UPDATE_VIDEO_DURATION", {
        duration: vid.current.duration + 0.3,
      });
      setLoaded(true);
      action("pause", true);
      vid.current.addEventListener('canplay', () => {
        if(vid.current) {
        vid.current
          .play()
          .then(() => {
            action("play", true);
            console.log('play 1');
          })
          .catch(() => {
            setMuted(true);
            vid.current &&
              vid.current.play().then(() => {
                action("play", true);
              }).finally(() => {
                action("play", true);
              });
          });
        }
      })
    }
  };

  const videoEnd = () => {
    console.log('Video has ended');
    setVideoEnd(true);
  }

  return (
    <>
      {story?.text && loaded && styleClass && (
        <div className={`${styleClass}`} style={{zIndex:'9999'}}>{story.text}</div>
      )}
      {story?.logo && <div className="video-logo">
        <img src={story?.logo} alt="" />
      </div>}
      <WithHeader {...{ story, globalHeader: config.header || (() => {}) }}>
        <WithSeeMore {...{ story, action }}>
          <div style={styles.videoContainer}>
            <video
              ref={vid}
              className="storyContent"
              src={story.url}
              controls={false}
              onLoadedData={videoLoaded}
              playsInline
              onWaiting={onWaiting}
              onPlaying={onPlaying}
              muted={muted}
              autoPlay
              webkit-playsinline="true"
              onEnded={videoEnd}
            />
            {/* {story?.overlayImg && <div style={{position: "absolute",top: '0', left:'0', width: '100%', height: '100%',background: '#000',zIndex:'999'}}>
              <img 
              src={story?.overlayImg}
              alt=""
              style={{width: '100%', height: '100%', objectFit: 'scale-down',objectPosition: 'center'}}
            />
            </div>} */}
            {!loaded && (
              <div
                style={{
                  width: width,
                  height: height,
                  position: "absolute",
                  left: 0,
                  top: 0,
                  background: "rgba(0, 0, 0, 0.9)",
                  zIndex: 9,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#ccc",
                }}
              >
                {loader || <Spinner />}
              </div>
            )}
          </div>
        </WithSeeMore>
      </WithHeader>
    </>
  );
};

const styles = {
  // storyContent: {
  //   width: "auto",
  //   maxWidth: "100%",
  //   maxHeight: "100%",
  //   margin: "auto",
  // },
  videoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export const tester: Tester = (story) => {
  return {
    condition: story.type === "video" || story.type === "video_text",
    priority: 2,
  };
};

export default {
  renderer,
  tester,
};
